
import { formatDateNormal } from "@/app/infrastructures/misc/Utils";
import { STIController } from "@/app/ui/controllers/STIController";
import {
  STISTTDueListRequest,
  STISTTDueSummaryListRequest
} from "@/data/payload/api/StiApiRequest";
import { OptionsClass } from "@/domain/entities/MainApp";
import { NeedToSTISTT } from "@/domain/entities/STI";
import { Vue } from "vue-class-component";

export default class NeedToSTIList extends Vue {
  controller = STIController;

  parameters = {
    bookedId: 0,
    bookedType: "",
    search: "",
    booked: "" as any,
    isNow: false,
    isOverdue: false
  };

  bookedOption = new OptionsClass();

  async beforeMount(): Promise<void> {
    this.parameters.bookedId = this.controller.STISTTDueSummaryData.id;
    this.bookedOption = new OptionsClass({
      name: this.controller.STISTTDueSummaryData.posName,
      value: this.controller.STISTTDueSummaryData.id.toString(),
      code: this.controller.STISTTDueSummaryData.type
    });
    await this.controller.getStiBookedList().then(res => {
      const bookedData = res.data.find(
        booked =>
          booked.bookedId.toString() === this.parameters.bookedId.toString()
      );
      if (bookedData?.bookedId) {
        this.parameters.booked = new OptionsClass({
          name: bookedData.bookedName,
          value: bookedData.bookedId.toString(),
          code: bookedData.bookedType
        });
      } else {
        if (this.bookedOption.name) {
          this.parameters.booked = this.bookedOption;
        }
      }
    });
    this.fetchNeedStiSummary();
    this.fetchSttDueList();
  }

  get STIBookedListOptions() {
    const options = this.controller.bookedListData.data.map(booked => {
      return new OptionsClass({
        name: booked.bookedName,
        value: booked.bookedId.toString(),
        code: booked.bookedType
      });
    });
    if (this.bookedOption.name) {
      return [...options, this.bookedOption];
    }
    return options;
  }

  get deadlinePayload() {
    if (this.parameters.isNow && this.parameters.isOverdue) return "";
    if (this.parameters.isNow) return "now";
    if (this.parameters.isOverdue) return "overdue";
    return "";
  }

  get columns() {
    return [
      {
        name: "No.",
        styleHead: "w-12 text-left",
        render: (_item: NeedToSTISTT, index: number) => {
          return (
            index +
            1 +
            this.controller.sttDueListData.pagination.limit *
              (this.controller.sttDueListData.pagination.page - 1)
          );
        }
      },
      {
        name: "No. bag",
        styleHead: "w-40 text-left",
        render: (item: NeedToSTISTT) => {
          return item.bagNo || "-";
        }
      },
      {
        name: "No. STT",
        styleHead: "w-40 text-left",
        render: (item: NeedToSTISTT) => {
          return item.sttNo
        }
      },
      {
        name: "No. referensi",
        styleHead: "w-40 text-left",
        render: (item: NeedToSTISTT) => {
          return item.refNo || "-";
        }
      },
      {
        name: "Status terakhir",
        styleHead: "w-32 text-left",
        render: (item: NeedToSTISTT) => {
          return `<div class="text-sm text-black-lp-300 px-1 bg-gray-lp-200 rounded">${item.sttLastStatusId}</div>`;
        }
      },
      {
        name: "Jenis pengiriman",
        styleHead: "w-40 text-left",
        render: (item: NeedToSTISTT) => {
          return `<div class="text-sm text-black-lp-300 px-1 bg-gray-lp-200 rounded">${item.sttProductType}</div>`;
        }
      },
      {
        name: "Total koli",
        styleHead: "w-32 text-left",
        render: (item: NeedToSTISTT) => {
          return item.sttTotalPiece;
        }
      },
      {
        name: "Total berat",
        styleHead: "w-32 text-left",
        render: (item: NeedToSTISTT) => {
          return item.sttGrossWeight;
        }
      },
      {
        name: "Kota tujuan",
        styleHead: "w-32 text-left",
        render: (item: NeedToSTISTT) => {
          return `<div class="text-sm text-black-lp-300 px-1 bg-gray-lp-200 rounded">${item.sttDestinationCityId}</div>`;
        }
      },
      {
        name: "Tanggal booking",
        styleHead: "w-48 text-left",
        render: (item: NeedToSTISTT) => {
          return `<div class="flex flex-col">
            <div>${formatDateNormal(
              item.sttBookedAt,
              "DD MMMM YYYY, HH:mm"
            )}</div> 
            <div class="text-sm text-gray-lp-600">${item.sttCreatedName || "-"}</div>
          </div>`;
        }
      },
      {
        name: "Nama POS/klien",
        styleHead: "w-48 text-left",
        render: (item: NeedToSTISTT) => {
          return item.sttBookedName;
        }
      },
      {
        name: "Batas waktu",
        styleHead: "w-48 text-left",
        render: (item: NeedToSTISTT) => {
          return item.deadline.toLowerCase() === "telat"
            ? `<div class="text-sm text-red-lp-510 bg-red-lp-600 rounded-full font-semibold px-3 py-1">${item.deadline}</div>`
            : `<div class="text-sm text-green-lp-400 bg-green-lp-300 rounded-full font-semibold px-3 py-1">${item.deadline}</div>`;
        }
      }
    ];
  }

  onSearch(val: string) {
    this.parameters.search = val;
    this.controller.setFirstPageSTTDue();
    this.fetchSttDueList();
  }

  async fetchSttDueList() {
    const payload = new STISTTDueListRequest({
      bookedId: this.parameters.booked?.value || "",
      bookedType: this.parameters.bookedType,
      deadline: this.deadlinePayload,
      page: this.controller.sttDueListData.pagination.page,
      limit: this.controller.sttDueListData.pagination.limit,
      search: this.parameters.search
    });

    await this.controller.getStiSttDueList(payload);
  }

  fetchNeedStiSummary() {
    const payload = new STISTTDueSummaryListRequest({
      bookedId: this.parameters.booked?.value || "",
      bookedType: this.parameters.booked?.code || ""
    });

    this.controller.getSTISTTDueSummary(payload);
  }

  downloadExcel() {
    const payload = new STISTTDueListRequest({
      bookedId: this.parameters.booked?.value || "",
      bookedType: this.parameters.booked?.code || "",
      deadline: this.deadlinePayload,
      search: this.parameters.search
    });

    this.controller.downloadSttDue(payload);
  }
}
